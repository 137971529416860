import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ConfigProvider, Spin} from 'antd';
import {IntlProvider} from "react-intl";

import AppLocale from './languages';

import Router from "./router/Router";
import {login} from "./redux/customise/customiseActions";
import {getLoggedInUser} from "./services/authService";
import 'moment/locale/pl';
import {useNavigate} from "react-router-dom";
import {readCookie, THEME, TOKEN} from "./utils/CookieManager";
import {useThemeManager} from "./utils/themeManager";
import 'react-international-phone/style.css';
import DeleteConfirmation from "./view/pages/shared/components/DeleteConfirmation";
import {ResponseInterceptor} from "./services/utils/apiAxios";
import {Helmet} from "react-helmet";
import environmentSettings from "./assets/environmentSettings.json";
export default function App() {

    const customise = useSelector(state => state.customise)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {changeTheme} = useThemeManager();
    const [loading, setLoading] = useState(false);

    const currentAppLocale = AppLocale[customise.language];

    useEffect(() => {
        document.querySelector("html").setAttribute("lang", customise.language);
    }, [customise.language]);

    useEffect(() => {
        if (customise.user && customise.token) return;

        async function fetchData(token) {
            const user = await getLoggedInUser(token);
            if (!user) {
                return;
            }
            dispatch(login(user));
        }

        const userToken = readCookie(TOKEN)
        if (userToken) {
            setLoading(true);
            dispatch(login({token: userToken}));
            fetchData(userToken).then().finally(() => {
                setLoading(false);
            });
        }
        const savedTheme = readCookie(THEME);
        if (savedTheme) {
            changeTheme(savedTheme);
        }
    }, [dispatch, customise.token]);

    return (<Spin spinning={loading} size="large">
        <Helmet>
            <title>CRM - {environmentSettings.name || "Head Start Agency"}</title>
        </Helmet>
            <ConfigProvider locale={currentAppLocale.antd} direction={customise.direction}>
                <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                        <ResponseInterceptor />
                        <Router/>
                        <DeleteConfirmation/>
                </IntlProvider>
            </ConfigProvider>
        </Spin>);
}
