import {useSelector} from "react-redux";

export function hasPermission(userPermissions, routePermissions) {
    return routePermissions.some(permission =>
        userPermissions?.includes(permission + "_VIEW") ||
        userPermissions?.includes(permission + "_EDIT")
    );
}

const checkPermission = (userPermissions, permissionTypes, actionType) => {
    if(!userPermissions || !permissionTypes || !actionType) return false;

    const checkSinglePermission = (type) =>
        userPermissions.includes(`${type}_${actionType}`);

    if (Array.isArray(permissionTypes)) {
        return permissionTypes.some(checkSinglePermission);
    } else {
        return checkSinglePermission(permissionTypes);
    }
};

export const canView = (userPermissions, permissionTypes) => checkPermission(userPermissions, permissionTypes, 'VIEW');
export const canEdit = (userPermissions, permissionTypes) => checkPermission(userPermissions, permissionTypes, 'EDIT');
export const isOnlyViewer = (userPermissions, permissionTypes) => !canEdit(userPermissions, permissionTypes);