const initialState = {
    variableCosts: [],
    loading: false,
};

export default function variableCostsReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_VARIABLE_COSTS_START':
            return { ...state, loading: true };
        case 'FETCH_VARIABLE_COSTS_SUCCESS':
            return { ...state, variableCosts: action.payload, loading: false };
        case 'FETCH_VARIABLE_COSTS_STOP':
            return { ...state, variableCosts: [...state.variableCosts, action.payload], loading: false  };
        case 'EDIT_VARIABLE_COST_SUCCESS':
            return {
                ...state,
                variableCosts: state.variableCosts.map(cost =>
                    cost.id === action.payload.variableCostId ? { ...cost, ...action.payload.variableCostData } : cost
                )
            };
        case 'DELETE_VARIABLE_COSTS_SUCCESS':
            return {
                ...state,
                variableCosts: state.variableCosts.filter(cost => !action.payload.includes(cost.id))
            };
        default:
            return state;
    }
}
