const initialState = {
    data: {},
    loading: false,
};

const genericReducer = (state = initialState, action) => {
    const { type, payload, entity } = action;

    switch (type) {
        case `GET_ALL_${entity}_START`:
            return { ...state, loading: true };
        case `GET_ALL_${entity}_SUCCESS`:
            return { ...state, data: { ...state.data, [entity]: payload }, loading: false };
        case `GET_ALL_${entity}_STOP`:
            return { ...state, data: { ...state.data, [entity]: payload }, loading: false };
        default:
            return state;
    }
};

export default genericReducer;
