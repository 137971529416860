import { lazy } from "react";
import {permissions} from "../../utils/permissions";
import Environments from "../../utils/environments";

const PagesRoutes = [
    /// FINANCES
  {
    path: "/finances/costs",
    component: lazy(() => import("../../view/pages/finances/costs/home/costsHome")),
    layout: "VerticalLayout",
    permissions: [permissions.FINANCES],
  },
  {
    path: "/finances/costs/fixed",
    component: lazy(() => import("../../view/pages/finances/costs/fixed/fixedCosts")),
    layout: "VerticalLayout",
    permissions: [permissions.FINANCES],
  },
  {
    path: "/finances/costs/fixed/add",
    component: lazy(() => import("../../view/pages/finances/costs/fixed/AddEdit/index")),
    layout: "VerticalLayout",
    permissions: [permissions.FINANCES],
  },
  {
    path: "/finances/costs/fixed/edit/:id",
    component: lazy(() => import("../../view/pages/finances/costs/fixed/AddEdit/index")),
    layout: "VerticalLayout",
    permissions: [permissions.FINANCES],
  },
  {
    path: "/finances/costs/variable",
    component: lazy(() => import("../../view/pages/finances/costs/variable/variableCosts")),
    layout: "VerticalLayout",
    permissions: [permissions.FINANCES],
  },
  {
    path: "/finances/costs/employeePage",
    component: lazy(() => import("../../view/pages/finances/costs/employee/employeeCosts")),
    layout: "VerticalLayout",
    permissions: [permissions.FINANCES]
  },
  {
    path: "/finances/profits/collaborations",
    component: lazy(() => import("../../view/pages/finances/profits/collaborations")),
    layout: "VerticalLayout",
    permissions: [permissions.FINANCES],
  },
  {
    path: "/finances/profits/other",
    component: lazy(() => import("../../view/pages/finances/profits/other")),
    layout: "VerticalLayout",
    permissions: [permissions.FINANCES],
  },
  {
    path: "/finances/costs/salary/*",
    component: lazy(() => import("../../view/pages/finances/salaryV2/index")),
    layout: "VerticalLayout",
    permissions: [permissions.FINANCES],
  },
  {
    path: "/finances/costs/salary/employee/:id",
    component: lazy(() => import("../../view/pages/finances/salaryV2/employeePage/index")),
    layout: "VerticalLayout",
    permissions: [permissions.FINANCES],
  },
  {
    path: "/finances/costs/salary/employee/:id/salary/add",
    component: lazy(() => import("../../view/pages/finances/salaryV2/salaryPlanCreator/index")),
    layout: "VerticalLayout",
    permissions: [permissions.FINANCES],
  },
  {
    path: "/finances/costs/salary/employees/add",
    component: lazy(() => import("../../view/pages/finances/salaryV2/employeeCreator/index")),
    layout: "VerticalLayout",
    permissions: [permissions.FINANCES],
  },
  {
    path: "/cashflow",
    component: lazy(() => import("../../view/pages/finances/cashflow/index")),
    layout: "VerticalLayout",
    permissions: [permissions.FINANCES],
  },
  {
    path: "/finances/expenses",
    component: lazy(() => import("../../view/pages/finances/expenses/index")),
    layout: "VerticalLayout",
  },
  ///// ADMIN USERS MANAGEMENT
  {
    path: "/administration/users",
    component: lazy(() => import("../../view/pages/administration/users")),
    layout: "VerticalLayout",
    permissions: [permissions.USERS_MANAGMENT],
  },
  {
    path: "/administration/influencers",
    component: lazy(() => import("../../view/pages/administration/influencers")),
    layout: "VerticalLayout",
    permissions: [permissions.INFLUENCERS],
  },
    //// COLLABORATIONS
  {
    path: "/collaborations/campaigns",
    component: lazy(() => import("../../view/pages/collaborations/campaigns/index")),
    layout: "VerticalLayout",
    permissions: [permissions.CAMPAIGNS],
  },
  {
    path: "/collaborations/details/:id",
    component: lazy(() => import("../../view/pages/collaborations/campaigns/campaignDetails/index")),
    layout: "VerticalLayout",
    permissions: [permissions.CAMPAIGNS],
  },
  {
    path: "/collaborations/campaigns/add",
    component: lazy(() => import("../../view/pages/collaborations/campaigns/createEditCampaign/index")),
    layout: "VerticalLayout",
    permissions: [permissions.CAMPAIGNS],
  },
  {
    path: "/collaborations/fights",
    component: lazy(() => import("../../view/pages/collaborations/fights/index")),
    layout: "VerticalLayout",
    permissions: [permissions.CAMPAIGNS],
  },
  {
    path: "/collaborations/fights/add",
    component: lazy(() => import("../../view/pages/collaborations/fights/createEditCampaign/index")),
    layout: "VerticalLayout",
    permissions: [permissions.CAMPAIGNS],
  },
  {
    path: "/collaborations/other",
    component: lazy(() => import("../../view/pages/collaborations/other/index")),
    layout: "VerticalLayout",
    permissions: [permissions.CAMPAIGNS],
  },
  {
    path: "/collaborations/other/add",
    component: lazy(() => import("../../view/pages/collaborations/other/createEditCampaign/index")),
    layout: "VerticalLayout",
    permissions: [permissions.CAMPAIGNS],
  },
  {
    path: "/collaborations/campaigns/edit/:id",
    component: lazy(() => import("../../view/pages/collaborations/campaigns/createEditCampaign/index")),
    layout: "VerticalLayout",
    permissions: [permissions.CAMPAIGNS],
  },
  {
    path: "/collaborations/influencers",
    component: lazy(() => import("../../view/pages/collaborations/influencers/index")),
    layout: "VerticalLayout",
    permissions: [permissions.CAMPAIGNS, permissions.INFLUENCERS],
  },
  {
    path: "/collaborations/clients",
    component: lazy(() => import("../../view/pages/collaborations/clients/index")),
    layout: "VerticalLayout",
    permissions: [permissions.CAMPAIGNS],
  },
  {
    path: "/collaborations/client/:id",
    component: lazy(() => import("../../view/pages/collaborations/campaigns/campaignsByClient/index")),
    layout: "VerticalLayout",
    permissions: [permissions.CAMPAIGNS],
  },
  {
    path: "/collaborations/influencer/:id",
    component: lazy(() => import("../../view/pages/collaborations/campaigns/campaignsByInfluencer/index")),
    layout: "VerticalLayout",
    permissions: [permissions.CAMPAIGNS],
  },
  {
    path: "/collaborations/calendar",
    component: lazy(() => import("../../view/pages/collaborations/calendar/index")),
    layout: "VerticalLayout",
    permissions: [permissions.CAMPAIGNS],
  },
  {
    path: "/settings",
    component: lazy(() => import("../../view/pages/settings/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/settings/*",
    component: lazy(() => import("../../view/pages/settings/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/content-management/hsa/influencers",
    component: lazy(() => import("../../view/pages/content-management/influencers")),
    layout: "VerticalLayout",
    environment: Environments.hsa
  },
  {
    path: "/content-management/hsa/partners",
    component: lazy(() => import("../../view/pages/content-management/partners")),
    layout: "VerticalLayout",
    environment: Environments.hsa
  },
  {
    path: "/content-management/hsa/case-studies",
    component: lazy(() => import("../../view/pages/content-management/case-studies")),
    layout: "VerticalLayout",
    environment: Environments.hsa
  },
  {
    path: "/content-management/teenz",
    component: lazy(() => import("../../view/pages/content-management/teenz")),
    layout: "VerticalLayout",
    environment: Environments.teenz
  }
];

export default PagesRoutes;
