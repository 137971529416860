import {message} from "antd";
import apiAxios from "../../../services/utils/apiAxios";
import {UPDATE_USER} from "../../../services/utils/apiEndpoints";
import {getAllUsers} from "../../administration/usersActions";

export const deleteItems = (endpoint, ids, onSuccess) => {
    return async dispatch => {
        try {
            if (!Array.isArray(ids)) {
                endpoint = `${endpoint}/${ids}`
            }
            const response = await apiAxios.delete(endpoint, { data: ids });

            if (response.data) {
                message.success('Usunięto pomyślnie');
            } else {
                message.error('Operacja nie powiodła się: ' + response.data.message);
                return;
            }
        } catch (error) {
            message.error('Wystąpił błąd podczas usuwania');
        }
        try {
            if (onSuccess && typeof onSuccess === 'function') {
                onSuccess();
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const showDeleteModal = (items, endpoint, onSuccess) => ({
    type: 'SHOW_DELETE_MODAL',
    payload: { items, endpoint, onSuccess }
});

export const hideDeleteModal = () => ({
    type: 'HIDE_DELETE_MODAL'
});
