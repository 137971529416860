import Cookies from "js-cookie";

export const THEME = 'theme';
export const TOKEN = 'token'

export const setCookie = (key, value, expires) => {
    Cookies.set(key, value, { expires });
}

export const readCookie = (key) => {
    return Cookies.get(key);
}

export const removeCookie = (key) => {
    Cookies.remove(key);
}

export const setLongTermCookie = (key, value) => {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 10);
    setCookie(key, value, expires);
};

export const setShortTermCookie = (key, value) => {
    setCookie(key, value, 1);
}