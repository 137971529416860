import React from 'react'
import logo from "../../../../assets/images/logo/logo.svg";

export default function Background() {
    return (
        <div className="hp-position-absolute-top-left hp-w-100 hp-h-100 hp-overflow-hidden">
            <img className="hp-position-absolute-center background-logo" src={logo} />
        </div>
    )
}
