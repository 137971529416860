import React from "react";
import {Link, useLocation} from "react-router-dom";

import {useSelector} from 'react-redux';

import {Menu, Tag} from "antd";

import navigation from "../../../../navigation";
import {hasPermission} from "../../../../utils/permissionsManager";
import Environments from "../../../../utils/environments";

const { SubMenu } = Menu;

export default function MenuItem(props) {
    const { onClose } = props;

    // Redux
    const customise = useSelector(state => state.customise)

    // Location
    const location = useLocation();
    const { pathname } = location;

    const splitLocation = pathname.split("/")

    const [openKeys, setOpenKeys] = React.useState([]);

    // Menu
    const splitLocationUrl =
        splitLocation[splitLocation.length - 2] +
        "/" +
        splitLocation[splitLocation.length - 1];

    const onTitleClick = (item) => {
        const currentIndex = openKeys.indexOf(item.key);
        const newOpenKeys = [...openKeys];

        if (currentIndex === -1) {
            newOpenKeys.push(item.key);
        } else {
            newOpenKeys.splice(currentIndex, 1);
        }

        setOpenKeys(newOpenKeys);
    };

    const isPartOfActiveSubmenu = (item) => {
        if (!item.children) return false;

        return item.children.some(child => {
            const childNavLink = child.navLink.split("/");
            const childPath = childNavLink.slice(-2).join("/");
            return splitLocationUrl === childPath;
        });
    };

    const menuItem = customise.user.permissions && navigation
        .filter((item, index) => {
            return (item.permissions
                ? hasPermission(customise.user.permissions, item.permissions)
                : true) && (item.environment ? item.environment === process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === Environments.staging : true);
        })
        .map((item, index) => {
        if (item.header) {
            return <Menu.ItemGroup key={index} title={item.header}></Menu.ItemGroup>;
        }

        if (item.children) {
                const itemNavLink = item.navLink?.split("/");
            const isItemActive = splitLocationUrl ===
                (item.navLink ? item.navLink.split("/").slice(-2).join("/") : '');
            const isSubmenuActive = isPartOfActiveSubmenu(item);

            const menuIcon = isItemActive || isSubmenuActive ? item.activeIcon || item.icon : item.icon;

                return (
                <SubMenu
                    key={item.id}
                    icon={menuIcon}
                    title={
                        <Link onClick={() => onTitleClick(item)} to={item.navLink}>{item.title}</Link>
                }
                    onTitleClick={onTitleClick}
                    className={itemNavLink &&
                        splitLocationUrl ===
                        itemNavLink[itemNavLink.length - 2] +
                        "/" +
                        itemNavLink[itemNavLink.length - 1]
                            ? "ant-menu-item-selected"
                            : "ant-menu-item-selected-in-active"
                    }>
                    {item.children.map((itemChildren, index) => {
                        if (!itemChildren.children) {
                            const childrenNavLink = itemChildren.navLink.split("/");

                            return (
                                // Level 2
                                <Menu.Item
                                    key={itemChildren.id}
                                    className={
                                        splitLocationUrl ===
                                            childrenNavLink[childrenNavLink.length - 2] +
                                            "/" +
                                            childrenNavLink[childrenNavLink.length - 1]
                                            ? "ant-menu-item-selected"
                                            : "ant-menu-item-selected-in-active"
                                    }
                                    onClick={onClose}
                                >
                                    <Link to={itemChildren.navLink}>{itemChildren.title}</Link>
                                </Menu.Item>
                            );
                        } else {
                            return (
                                // Level 3
                                <SubMenu key={itemChildren.id} title={itemChildren.title}>
                                    {itemChildren.children.map((childItem, index) => {
                                        const childrenItemLink = childItem.navLink.split("/");

                                        return (
                                            <Menu.Item
                                                key={childItem.id}
                                                className={
                                                    splitLocationUrl ===
                                                        childrenItemLink[childrenItemLink.length - 2] +
                                                        "/" +
                                                        childrenItemLink[childrenItemLink.length - 1]
                                                        ? "ant-menu-item-selected"
                                                        : "ant-menu-item-selected-in-active"
                                                }
                                                onClick={onClose}
                                            >
                                                <Link to={childItem.navLink}>{childItem.title}</Link>
                                            </Menu.Item>
                                        );
                                    })}
                                </SubMenu>
                            );
                        }
                    })}
                </SubMenu>
            );
        } else {
            const itemNavLink = item.navLink ? item.navLink.split("/") : '';
            const isActive = splitLocationUrl ===
                (item.navLink ? item.navLink.split("/").slice(-2).join("/") : '');

            const menuIcon = isActive ? item.activeIcon || item.icon : item.icon;

            return (
                // Level 1
                <Menu.Item
                    key={item.id}
                    icon={menuIcon}
                    onClick={onClose}
                    className={
                        splitLocation[splitLocation.length - 2] +
                            "/" +
                            splitLocation[splitLocation.length - 1] ===
                            itemNavLink[itemNavLink.length - 2] +
                            "/" +
                            itemNavLink[itemNavLink.length - 1]
                            ? "ant-menu-item-selected"
                            : "ant-menu-item-selected-in-active"
                    }
                    style={item.tag && { pointerEvents: 'none' }}
                >
                    {
                        item.tag ? (
                            <a href="#" className="hp-d-flex hp-align-items-center hp-d-flex-between">
                                <span>{item.title}</span>
                                <Tag className="hp-mr-0 hp-border-none hp-text-color-black-100 hp-bg-success-3 hp-border-radius-full hp-px-8" style={{ marginRight: -14 }}>{item.tag}</Tag>
                            </a>
                        ) : (
                            <Link to={item.navLink}>{item.title}</Link>
                        )
                    }
                </Menu.Item>
            );
        }
    })

    return (
        <Menu
            mode="inline"
            defaultOpenKeys={[
                splitLocation.length === 5
                    ? splitLocation[splitLocation.length - 3]
                    : null,
                splitLocation[splitLocation.length - 2],
            ]}
            openKeys={openKeys}
            onOpenChange={(keys) => setOpenKeys(keys)}
            theme={customise.theme === "light" ? "light" : "dark"}
            className="hp-bg-black-20 hp-bg-dark-90"
        >
            {menuItem}
        </Menu>
    );
};
