import {Form, Input} from "antd";
import React from "react";

const PasswordInput = ({label, name, onChange, hidePassword = true, inputProps}) => {
    return( <Form.Item
        label={label}
        name={name}
        autoComplete="off"
        rules={[
            {
                required: true, message: "Proszę wprowadź nowe hasło",
            },
            {
                validator: (_, value) => {
                    if (!value || value.length === 0) {
                        return Promise.reject();
                    }
                    if (value.length < 8) {
                        return Promise.reject("Hasło musi mieć co najmniej 8 znaków");
                    }
                    if (!/[A-Z]/.test(value)) {
                        return Promise.reject("Hasło musi zawierać co najmniej jedną dużą literę");
                    }
                    if (!/[a-z]/.test(value)) {
                        return Promise.reject("Hasło musi zawierać co najmniej jedną małą literę");
                    }
                    if (!/[0-9]/.test(value)) {
                        return Promise.reject("Hasło musi zawierać co najmniej jedną cyfrę");
                    }
                    if (!/[#?!@$%^&*\-(){}[\]\\|;:'",.<>/]/.test(value)) {
                        return Promise.reject("Hasło musi zawierać co najmniej jeden znak specjalny (#?!@$%^&*-)");
                    }
                    return Promise.resolve();
                },
            }
        ]}
    >
        {hidePassword ?
        <Input.Password
            autoComplete="off"
            placeholder="Nowe hasło"
            onChange={onChange}
            {...inputProps}
        /> :
            <Input
                autoComplete="off"
                placeholder="Nowe hasło"
                onChange={onChange}
                {...inputProps}
            />
        }
    </Form.Item>)
}

export default PasswordInput;