import antdPL from  "antd/lib/locale-provider/pl_PL";
import enMessages from "../locales/pl_PL.json";

const plLang = {
    messages: {
        ...enMessages
    },
    antd: antdPL,
    locale: 'pl-PL',
};

export default plLang;
