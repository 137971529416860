import {
    GET_LOGGED_USER_ENDPOINT,
    LOGIN_ENDPOINT,
    RESET_PASSWORD_ENDPOINT,
} from "./utils/apiEndpoints";
import apiAxios from "./utils/apiAxios";
import {removeCookie, setShortTermCookie, TOKEN} from "../utils/CookieManager";

export const login = async (email, password, captchaKey) => {
    try {
        const response = await apiAxios.post(LOGIN_ENDPOINT, { email, password, captchaKey });
        if (response.data?.token) {
            setShortTermCookie(TOKEN, response.data.token)
        }
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        return error;
    }
};

export const resetPassword = async (email, captchaKey) => {
    try {
        return await apiAxios.post(`${RESET_PASSWORD_ENDPOINT}?email=${email}&captchaKey=${captchaKey}`);
    } catch (error) {
        console.error('Error during reset password:', error);
        return error;
    }
};

export const restorePassword = async (token, newPassword) => {
    try {
        return await  apiAxios.post(`${RESET_PASSWORD_ENDPOINT}/${token}`, { newPassword });
    } catch (error) {
        console.error('Error during reset password:', error);
        return error;
    }
};

export const logout = () => {
    removeCookie(TOKEN)
};

export const getLoggedInUser = async () => {
    try {
        const response = await apiAxios.get(GET_LOGGED_USER_ENDPOINT);
        return response.data;
    } catch (error) {
        console.error('Error during load user data from server:', error);
        return null;
    }
};
