import React, { useState } from 'react';
import {message, Modal, Row} from 'antd';
import {RiAlertLine} from "react-icons/ri";
import apiAxios from "../../../../services/utils/apiAxios";
import {useDispatch, useSelector} from "react-redux";
import {deleteItems, hideDeleteModal} from "../../../../redux/general/delete/deleteActions";

const DeleteConfirmation = () => {
    const dispatch = useDispatch();
    const { isVisible, itemsToDelete, deleteEndpoint, onSuccess } = useSelector(state => state.deleteConfirmation);

    const handleDelete = () => {
        let idsToDelete;

        if (Array.isArray(itemsToDelete)) {
            idsToDelete = itemsToDelete.map(item => item.id);
        } else if (itemsToDelete && itemsToDelete.id) {
            idsToDelete = itemsToDelete.id;
        }

        if (idsToDelete) {
            dispatch(deleteItems(deleteEndpoint, idsToDelete, () => {
                if (onSuccess && typeof onSuccess === 'function') {
                  dispatch(onSuccess());
                }
            })).finally(() =>  dispatch(hideDeleteModal()));
        }
    };

    return (
        <Modal
            open={isVisible}
            title={<Row justify="start" className="hp-align-items-center"><RiAlertLine size={24} style={{ color: 'red', marginRight: '10px' }} />Usuwanie</Row>}
            onOk={handleDelete}
            onCancel={() => dispatch(hideDeleteModal())}
            okText="Usuń"
            cancelText="Anuluj"
            bodyStyle={{ padding: "24px 24px" }}
            okButtonProps={{ danger: true, type: "primary" }}
            zIndex={3000}
        >
            <p className="hp-mb-0">Czy na pewno chcesz usunąć? Ta operacja jest nieodwracalna.</p>
            <ul>
                {Array.isArray(itemsToDelete) ? (
                    itemsToDelete.map(item => (
                        <li key={item.id}>{item.name}</li>
                    ))
                ) : (
                    itemsToDelete && <li key={itemsToDelete.id}>{itemsToDelete.name}</li>
                )}
            </ul>

        </Modal>
    );
};

export default DeleteConfirmation;
