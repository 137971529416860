import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Form, Input, Button, Checkbox } from "antd";
import Background from "../../shared/main_layout/background";
import Header from "../header";
import Footer from "../../shared/main_layout/footer";
import { useDispatch } from "react-redux";
import { login } from "../../../../redux/customise/customiseActions";
import { login as loginService } from "../../../../services/authService";
import logo from "../../../../assets/images/logo/logo.svg";
import {injectIntl} from "react-intl";
import IntlMessages, {useIntlMessage} from "../../../../layout/components/lang/IntlMessages";
import ReCaptcha from "../../../../services/reCaptchaService";

export default function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const intlMessage = useIntlMessage();
    const [captchaKey, setCaptchaKey] = useState();
    const enableRecaptcha = process.env.REACT_APP_ENABLE_RECAPTCHA === 'true';

    const handleLogin = async () => {

        if (enableRecaptcha && !captchaKey) {
            setError(intlMessage("Proszę uzupełnij weryfikację CAPTCHA"));
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const user = await loginService(email, password, captchaKey);
            if(user.token) {
                dispatch(login(user));
                navigate("/");
            }
            if(user.message === "Request failed with status code 401" || user.code === "ERR_BAD_REQUEST")
            {
                setError(intlMessage("Login failed. Please check your credentials."));
            }
        } catch (error) {
            setError(intlMessage("Login failed. Please check your credentials."));
        } finally {
            setLoading(false);
            window.grecaptcha.reset();
            setCaptchaKey(undefined);
        }
    };

    const onEmailChange = (e) => {
        setEmail(e.target.value);
        setError(null);
    };

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
        setError(null);
    };

    useEffect(() => {
        if (error) {
            window.scrollTo(0, 0);
        }
    }, [error]);

    return (
        <Row className="hp-authentication-page hp-d-flex" style={{ flexDirection: "column"}}>
            <Background />
            <div>
            <Col span={24}><Header /></Col>

            <Col flex="1 0 0" className="hp-px-32">
                <Row className="hp-h-100 hp-m-auto" align="middle" style={{ maxWidth: 360 }}>
                    <Col span={24}>
                        <div className="hp-text-center">
                        <img className="hp-m-auto" height="100px" width="100px" src={logo} alt="logo" />
                        </div>
                        <h2 className="hp-text-center"><IntlMessages id="sign-in-title"/></h2>
                        {error && <p style={{ color: "red" }} className="hp-text-center" dangerouslySetInnerHTML={{__html: error}}/>}
                        <Form
                            layout="vertical"
                            name="basic"
                            initialValues={{remember: true}}
                            className="hp-mt-sm-16 hp-mt-20"
                            onFinish={handleLogin}
                        >
                            <Form.Item label={intlMessage("Email :")} className="hp-mb-16" name="email"
                                       rules={[{required: true, message: intlMessage('Please input your email!')}]}>
                                <Input type="email" onChange={onEmailChange} value={email}/>
                            </Form.Item>
                            <Form.Item label={intlMessage("Password :")} className="hp-mb-8" name="password"
                                       rules={[{required: true, message: intlMessage('Please input your password!')}]}>
                                <Input.Password onChange={onPasswordChange} value={password}/>
                            </Form.Item>

                            <Row align="middle" justify="space-between">
                                <Form.Item className="hp-mb-0" name="remember" valuePropName="checked">
                                    <Checkbox><IntlMessages id="Remember me"></IntlMessages></Checkbox>
                                </Form.Item>

                                <Link className="hp-button hp-text-color-black-80 hp-text-color-dark-40"
                                      to="/recover-password">
                                    <IntlMessages id="Forgot Password?"/>
                                </Link>
                            </Row>
                            <div className="hp-d-flex-full-center">
                                <Form.Item className="hp-mt-16 hp-mb-0"
                                >

                                    <ReCaptcha setCaptchaKey={setCaptchaKey}/>

                        </Form.Item>   </div>

                        <Form.Item className="hp-mt-16 hp-mb-0">
                                <Button block type="primary" htmlType="submit" loading={loading}>
                                    <IntlMessages id="Sign in"/>
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Col>

            <Col span={24}><Footer /></Col>
            </div>
        </Row>
    );
};
