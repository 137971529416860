import ReCAPTCHA from "react-google-recaptcha";
import {useSelector} from "react-redux";

const ReCaptcha = ({setCaptchaKey}) => {
    const theme = useSelector(state => state.customise.theme)

    const handleCaptchaResponseChange = (response) => {
        setCaptchaKey(response)
    };

    return (
        <ReCAPTCHA
            sitekey="6LeW50ApAAAAAPdNPI8_C7X8hcrUNolDzMMVoMBz"
            onChange={handleCaptchaResponseChange}
            theme={theme}
        />
    );
}

export default ReCaptcha;