import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import logo from "../../../../assets/images/logo/logo.svg";
import logo_small from "../../../../assets/images/logo/logo-small.svg";
import environmentSettings from "../../../../assets/environmentSettings.json";

export default function MenuLogo(props) {
  const customise = useSelector(state => state.customise)

  return (
    <div className="hp-header-logo hp-d-flex hp-align-items-center">
      <Link
        to="/"
        onClick={props.onClose}
        className="hp-position-relative hp-d-flex"
      >
          <img className="hp-logo" src={props.small ? logo_small : logo} alt="logo" />

      </Link>

      {
        !props.small && (
              <Link
                  to="/"
                  onClick={props.onClose}
            className="hp-caption hp-font-weight-600 hp-text-color-black-80 hp-p-8"
          >
              {environmentSettings.logoName || "HEAD START AGENCY"}
              </Link>
        )
      }
    </div>
  );
};
