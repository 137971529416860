import { combineReducers } from "redux";

import customiseReducer from "./customise/customiseReducer";
import usersAdministrationReducer from "./administration/userReducer";
import fixedCostsReducer from "./finances/fixedCostsReducer";
import variableCostsReducer from "./finances/variableCostsReducer";
import deleteConfirmationReducer from "./general/delete/deleteReducer";
import financeUtilsReducer from "./finances/financeUtilsReducer";
import influencerAdministrationReducer from "./administration/influencersReducer";
import genericReducer from "./general/generalReducer";
import salaryReducer from "./finances/salaryReducer";
import tagsReducer from "./collaborations/tagsReducer";
import variableProfitsReducer from "./finances/variableProfitsReducer";
import expensesReducer from "./finances/expensesReducer";
import employeeCostReducer from "./finances/employeeCostsReducer";
import contentReducer from "./content-management/contentReducer";
import companyReducer from "./company/companyReducer";

const rootReducer = combineReducers({
  customise: customiseReducer,
  userAdministration: usersAdministrationReducer,
  influencerAdministration: influencerAdministrationReducer,
  fixedCosts: fixedCostsReducer,
  variableCosts: variableCostsReducer,
  deleteConfirmation: deleteConfirmationReducer,
  finances: financeUtilsReducer,
  salaries: salaryReducer,
  expenses: expensesReducer,
  employeeCosts: employeeCostReducer,
  influencerTags: tagsReducer,
  data: genericReducer,
  variableProfits: variableProfitsReducer,
  contentManagement: contentReducer,
  companyInformation: companyReducer
});

export default rootReducer;
