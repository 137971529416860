import axios from 'axios';
import urlJoin from 'url-join';
import store from "../../redux/store";
import {message} from "antd";
import {handleErrorResponse, handleError} from "./ErrorHandler";
import {useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {logout as logoutService} from "../authService";
import {logout} from "../../redux/customise/customiseActions";
import {useDispatch} from "react-redux";
import errorMessages, {isTokenError} from "./errorMessages";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_URL = '/api';

const apiAxios = axios.create({
    baseURL: urlJoin(API_BASE_URL, API_URL),
    timeout: 30000
});

apiAxios.interceptors.request.use((config) => addTokenToRequest(config));

const addTokenToRequest = (config) => {
    const state = store.getState();
    const token = state.customise?.token;

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
};

export const ResponseInterceptor = () => {
    const navigate = useNavigate()
    const interceptorId = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        interceptorId.current = apiAxios.interceptors.response.use(
            response => {
                if (response.data !== null && response.data !== undefined) {
                    if (response.data.success) {
                        return response.data;
                    } else if (response.data.success === false) {
                        handleErrorResponse(response);
                        return response.data;
                    } else {
                        return response;
                    }
                } else {
                    message.error('Operacja nie powiodła się');
                }
                return response;
            },
            error => {
                if(isTokenError(error.response.data.enumMessage))
                {
                    message.warning(errorMessages(error.response.data.enumMessage));

                    logoutService();
                    dispatch(logout());

                    navigate('/sign-in');

                    return {data: {}};
                }

                error.response ? handleErrorResponse(error.response) : handleError(error);

                return Promise.reject(error);
            }
        );
        return () => {
            apiAxios.interceptors.response.eject(interceptorId.current);
        };
    }, [navigate]);

    return null;
};


export default apiAxios;
