const errorTranslations = {
    'NOT_FOUND': 'Nie znaleziono zasobu. Sprawdź poprawność żądania.',
    'INTERNAL_ERROR': 'Wystąpił wewnętrzny błąd serwera. Spróbuj ponownie później.',
    'BAD_CREDENTIALS': 'Brak uprawnień.',
    'INVALID_INPUT': 'Wystąpił błąd. Sprawdź poprawność wprowadzonych danych.',
    'AUTHENTICATION_FAILED': 'Błąd autoryzacji',
    'RELATIONSHIP_CONFLICT': 'Nie można usunąć tego elementu, ponieważ jest powiązany z innymi danymi. Usuń najpierw wszystkie powiązane elementy.',
    'INFLUENCER_ALREADY_ASSIGNED': 'Influencer posiada przypisane konto.',
    'ACCOUNT_HAS_BEEN_BLOCKED': 'Twoje konto zostało zablokowane, z powodu zbyt dużej ilości prób logowania',
    'TOO_MANY_REQUESTS' : 'Wykonano zbyt wiele prób logowania. Odczekaj 30 minut i spróbuj ponownie później.',
    'TOO_FAST_CHECK_MAIL': 'Zbyt wiele prób resetu hasła w krótkim czasie. Sprawdź swoją skrzynkę mailową lub spróbuj ponownie później.',
    'COST_IS_ACCEPTED': 'Nie można edytować kosztu, który został już zaakceptowany.',
};

const tokenErrorTranslations = {
    'TOKEN_EXPIRED': 'Twoja sesja wygasła. Zaloguj się ponownie.',
    'INVALID_TOKEN': 'Wystąpił problem z autentykacją. Zaloguj się ponownie.',
    'TOKEN_UNSUPPORTED': 'Nieobsługiwany typ tokenu. Skontaktuj się z administratorem serwisu.',
    'TOKEN_EMPTY': 'Wystąpił problem z autentykacją. Zaloguj się ponownie.'
};

export const errorMessages = (error) => {
    return errorTranslations[error] || tokenErrorTranslations[error]  || 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.';
}

export const isTokenError = (error) => {
    return Object.keys(tokenErrorTranslations).includes(error);
};

export default errorMessages;
