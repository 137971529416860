import {message} from "antd";
import apiAxios from "../../services/utils/apiAxios";
import {showDeleteModal} from "./delete/deleteActions";

export const createGenericActions = (entity, apiEndpoints) => {
    const {
        ADD, DELETE, GET_ALL, GET_ONE, UPDATE
    } = apiEndpoints;

    const getAll = () => async dispatch => {
        dispatch({ type: `GET_ALL_${entity}_START`, entity: entity });
        try {
            const response = await apiAxios.get(GET_ALL);
            if (response.data) {
                dispatch({ type: `GET_ALL_${entity}_STOP`, payload: response.data, entity: entity });
            } else {
                dispatch({ type: `GET_ALL_${entity}_STOP`, payload: undefined, entity: entity });
                message.error(`Błąd podczas pobierania danych ${entity}`);
            }
        } catch (error) {
            dispatch({ type: `GET_ALL_${entity}_STOP`, payload: undefined, entity: entity });
            message.error(`Błąd podczas pobierania danych ${entity}`);
        }
    };

    const getOne = (id) => {
        return async () => {
            await apiAxios
                .get(`${GET_ONE}/${id}`)
                .then((response) => {
                    if (response.data?.success) {
                        return response.data.data
                    } else {
                        message.error(`Błąd podczas pobierania danych ${entity}`)
                    }
                })
                .catch((err) => console.error(err));
        };
    };

    const edit = (entityId, entityData) => {
        return async dispatch => {
            try {
                const response = await apiAxios.put(`${UPDATE}/${entityId}`, entityData);
                if (response.data) {
                    message.success(`Pomyślnie zmodyfikowano ${entity}`);
                    dispatch(getAll());
                } else {
                    message.error(`Błąd podczas modyfikacji ${entity}`);
                }
            } catch (err) {
                console.log(err);
            }
        };
    };

    const add = (entityData) => {
        return async dispatch => {
            try {
                const response = await apiAxios.post(ADD, entityData);
                if (response.data) {
                    message.success(`Pomyślnie dodano ${entity}`);
                    dispatch(getAll());
                } else {
                    message.error(`Błąd podczas dodawania ${entity}`);
                }
            } catch (err) {
                console.log(err);
            }
        };
    };

    const deleteEntities = (items) => {
        return async dispatch => {
            dispatch(showDeleteModal(items, DELETE, () => getAll()));
        }
    };

    return { getAll, getOne, edit, add, deleteEntities };
}
