export const THEME = "THEME";
export const CONTENT_WIDTH = "CONTENT_WIDTH";
export const SIDEBAR_COLLAPSED = "SIDEBAR_COLLAPSED";
export const SIDEBAR_COLLAPSE_BUTTON = "SIDEBAR_COLLAPSE_BUTTON";
export const LAYOUT_CHANGE = "LAYOUT_CHANGE";
export const NAVIGATION_FULL = "NAVIGATION_FULL";
export const NAVIGATION_BG = "NAVIGATION_BG";
export const DIRECTION = "DIRECTION";
export const LANGUAGE = "LANGUAGE";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USER_OPTIONS = "USER_OPTIONS";