import { Award } from 'iconsax-react';

import IntlMessages from "../layout/components/lang/IntlMessages";
import {
    RiArrowDownDoubleFill,
    RiArrowDownDoubleLine,
    RiBarChart2Fill,
    RiBarChart2Line,
    RiBookMarkFill,
    RiBookMarkLine,
    RiBoxingFill,
    RiBoxingLine,
    RiBriefcase3Fill,
    RiBriefcase3Line,
    RiCalendarFill,
    RiCalendarTodoLine,
    RiDashboardFill,
    RiDashboardLine,
    RiMegaphoneFill,
    RiMegaphoneLine, RiMoneyDollarCircleFill,
    RiMoneyDollarCircleLine, RiPagesFill, RiPagesLine, RiShapesFill, RiShapesLine,
    RiTeamFill,
    RiTeamLine,
    RiUserFill,
    RiUserLine,
    RiUserStarFill,
    RiUserStarLine, RiWallet2Fill, RiWallet2Line
} from "react-icons/ri";
import {permissions} from "../utils/permissions";
import {HSAIcon, HSAIconFill, TeenzIcon, TeenzIconFill} from "../assets/icons/hsaIcons";
import Environments from "../utils/environments";

const pages = [
/*    {
        id: "dashboard-header",
        header: "Dashboard",
    },
    {
        id: "dashboard",
        title: "Dashboard",
        navLink: "/",
        icon: <RiDashboardLine  size={18}/>,
        activeIcon: <RiDashboardFill size={18}/>,
    },*/
    {
        id: "finances",
        header: "Finanse",
    },
    {
        id: "cash-flow",
        title: "Cash-Flow",
        icon: <RiBarChart2Line  size={18}/>,
        activeIcon: <RiBarChart2Fill size={18}/>,
        navLink: "/cashflow",
        permissions: [permissions.FINANCES]
    },
    {
        id: "income",
        title: "Przychody",
        icon: <RiMoneyDollarCircleLine size={18} />,
        activeIcon: <RiMoneyDollarCircleFill size={18}/>,
        permissions: [permissions.FINANCES],
        children: [
            {
                id: "collaboration-income",
                title: "Współprace",
                navLink: "/finances/profits/collaborations",
            },
            {
                id: "other-income",
                title: "Pozostałe",
                navLink: "/finances/profits/other",
            }
            ]
    },
    {
        id: "costs",
        title: "Koszty",
        icon: <RiArrowDownDoubleLine  size={18} />,
        activeIcon: <RiArrowDownDoubleFill size={18}/>,
        permissions: [permissions.FINANCES],
        children: [
            {
                id: "fixed-costs",
                title: "Stałe",
                navLink: "/finances/costs/fixed",
            },
            {
                id: "variable-costs",
                title: "Zmienne",
                navLink: "/finances/costs/variable",

            },
            {
                id: "salaries",
                title: "Pracownicy",
                navLink: "/finances/costs/salary",
            },
            {
                id: "employeePage-costs",
                title: "Wydatki pracowników",
                navLink: "/finances/costs/employeePage",
            }
        ],
    },
    {
        id: "expenses",
        title: "Wydatki",
        icon: <RiWallet2Line size={18}/>,
        activeIcon: <RiWallet2Fill size={18}/>,
        navLink: "/finances/expenses"
    },

/*    {
        id: "obligations",
        title: "Zobowiązania",
        icon: <RiBookMarkLine size={18}/>,
        activeIcon: <RiBookMarkFill size={18}/>,
        permissions: [permissions.FINANCES]
    },*/
    {
        id: "collaborations",
        header: "Współprace",
        permissions: [permissions.CAMPAIGNS, permissions.INFLUENCERS]
    },
    {
        id: "campaigns",
        title: "Kampanie",
        permissions: [permissions.CAMPAIGNS],
        icon: <RiMegaphoneLine size={18}/>,
        activeIcon: <RiMegaphoneFill size={18}/>,
        navLink: "/collaborations/campaigns",
    },
    {
        title: "Walki",
        id: "fights",
        permissions: [permissions.CAMPAIGNS],
        icon: <RiBoxingLine size={18}/>,
        activeIcon: <RiBoxingFill size={18}/>,
        navLink: "/collaborations/fights",
    },
    {
        title: "Pozostałe",
        id: "other",
        permissions: [permissions.CAMPAIGNS],
        icon: <RiShapesLine  size={18}/>,
        activeIcon: <RiShapesFill size={18}/>,
        navLink: "/collaborations/other",
    },
    {
        id: "campaigns-calendar",
        title: "Kalendarz",
        permissions: [permissions.CAMPAIGNS],
        icon: <RiCalendarTodoLine size={18}/>,
        activeIcon: <RiCalendarFill size={18}/>,
        navLink: "/collaborations/calendar",
    },
    {
        id: "influencers",
        title: "Influencerzy",
        icon: <RiUserStarLine size={18} />,
        activeIcon: <RiUserStarFill size={18} />,
        permissions: [permissions.CAMPAIGNS, permissions.INFLUENCERS],
        navLink: "/collaborations/influencers",
    },
    {
        id: "clients",
        title: "Firmy",
        icon: <RiBriefcase3Line size={18} />,
        activeIcon: <RiBriefcase3Fill size={18} />,
        permissions: [permissions.CAMPAIGNS],
        navLink: "/collaborations/clients",
    },
    {
        id: "content-management",
        header: "Zarządzanie treścią",
        permissions: [permissions.WEBSITE]
    },
    {
        id: "content",
        title: "Strona HSA",
        icon: <HSAIcon size={18}/>,
        activeIcon: <HSAIconFill size={18}/>,
        permissions: [permissions.WEBSITE],
        environment: Environments.hsa,
        children: [
            {
                id: "content-influencers",
                title: "Influencerzy",
                navLink: "/content-management/hsa/influencers",
            },
            {
                id: "content-partners",
                title: "Partnerzy",
                navLink: "/content-management/hsa/partners",
            },
            {
                id: "content-case-studies",
                title: "Case Studies",
                navLink: "/content-management/hsa/case-studies",
            }
            ]
    },
    {
        id: "content-teenz",
        title: "Strona TeenZ",
        icon: <TeenzIcon size={18}/>,
        activeIcon: <TeenzIconFill size={18}/>,
        permissions: [permissions.WEBSITE],
        navLink: "/content-management/teenz",
        environment: Environments.teenz
    },
    {
        id: "administration",
        header: "Administracja",
        permissions: [permissions.USERS_MANAGMENT]
    },
    {
        id: "users-administration",
        title: "Użytkownicy",
        icon: <RiUserLine size={18} />,
        activeIcon: <RiUserFill size={18} />,
        navLink: "/administration/users",
        permissions: [permissions.USERS_MANAGMENT]
    },
/*    {
        id: "managers-administration",
        title: "Menadżerowie",
        icon: <RiTeamLine size={18} />,
        activeIcon: <RiTeamFill size={18} />,
        navLink: "/administration/managers",
        permissions: [permissions.USERS_MANAGMENT]
    },*/
    {
        id: "influencers-administration",
        title: "Influencerzy",
        icon: <RiUserStarLine size={18} />,
        activeIcon: <RiUserStarFill size={18} />,
        permissions: [permissions.USERS_MANAGMENT],
        navLink: "/administration/influencers",
    }
];

export default pages
