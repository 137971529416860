import React, {useContext} from "react";
import {FormattedMessage, injectIntl, IntlContext } from "react-intl";

const InjectMassage = props => <FormattedMessage {...props} />;

const useIntlMessage = () => {
    const intl = useContext(IntlContext);
    return (id, values) => {
        try {
            return intl.formatMessage({ id }, values);
        } catch (e) {
            return id;
        }
    };
};

export default injectIntl(InjectMassage, {
    withRef: false
});

export {InjectMassage, useIntlMessage};
