import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import { Row, Col, Form, Input, Button } from "antd";
import Background from "../../shared/main_layout/background";
import Header from "../header";
import Footer from "../../shared/main_layout/footer";
import IntlMessages, {useIntlMessage} from "../../../../layout/components/lang/IntlMessages";
import ReCaptcha from "../../../../services/reCaptchaService";
import {login as loginService, resetPassword} from "../../../../services/authService";
import {login} from "../../../../redux/customise/customiseActions";

export default function RecoverPassword() {
    const navigate = useNavigate();
  const intlMessage = useIntlMessage();
  const [captchaKey, setCaptchaKey] = useState();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const enableRecaptcha = process.env.REACT_APP_ENABLE_RECAPTCHA === 'true';
  const [recoverComplete, setRecoverComplete] = useState(false);

    const handleResetPassword = async () => {

        if (enableRecaptcha && !captchaKey) {
            setError(intlMessage("Proszę uzupełnij weryfikację CAPTCHA"));
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await resetPassword(email, captchaKey);
            if(response.data) {
                setRecoverComplete(true);
            }
            if(response.data.message === "Request failed with status code 401" || user.code === "ERR_BAD_REQUEST")
            {
                setError("Wprowadzony e-mail nie istnieje.");
            }
        } catch (error) {
            setError("Wystąpił błąd podczas przetwarania operacji. Spróbuj ponownie.");
        } finally {
            setLoading(false);
            window.grecaptcha.reset();
            setCaptchaKey(undefined);
        }
    };

    const onEmailChange = (e) => {
        setEmail(e.target.value);
        setError(null);
    };

    return (
    <Row className="hp-authentication-page hp-d-flex" style={{ flexDirection: "column" }}>
      <Background />

      <Col span={24}>
        <Header />
      </Col>

      <Col flex="1 0 0" className="hp-px-32">
        <Row className="hp-h-100 hp-m-auto" align="middle" style={{ maxWidth: 400 }}>
          <Col span={24}>
            <h2 className="hp-text-center"><IntlMessages id="Recover Password"/></h2>
              { recoverComplete ?
                  <>
                      <p className="hp-text-color-black-80 hp-text-center">Twoje hasło zostało pomyślnie zresetowane. Sprawdź swoją skrzynkę mailową.</p>

                      <Button block type="primary" onClick={() => navigate("/sign-in")} className={"hp-p-0 hp-mt-16"}>
                          Powrót do logowania
                      </Button>
                  </>
                  :
                  <>
                      <p className="hp-text-color-black-80 hp-text-center">Wpisz adres e-mail konta, do którego chcesz odzyskać dostęp</p>
              {error && <p style={{ color: "red" }} className="hp-text-center" dangerouslySetInnerHTML={{__html: error}}/>}
            <Form
              layout="vertical"
              name="basic"
              className="hp-mt-sm-16 hp-mt-32"
              onFinish={handleResetPassword}
            >
                <Form.Item label={intlMessage("Email :")} className="hp-mb-16" name="email"
                           rules={[{required: true, message: intlMessage('Please input your email!')}]}>
                    <Input type="email" onChange={onEmailChange} value={email}/>
                </Form.Item>

                <div className="hp-d-flex-full-center">
                    <Form.Item className="hp-mt-16 hp-mb-0"
                    >
                        <ReCaptcha setCaptchaKey={setCaptchaKey}/>

                    </Form.Item>   </div>

              <Form.Item className="hp-mt-16 hp-mb-8">
                  <Button block type="primary" htmlType="submit" loading={loading}>
                      <IntlMessages id="Reset Password"/>
                </Button>
              </Form.Item>
            </Form>


            <div className="hp-form-info hp-text-center  hp-mt-12">

              <Link
                  to="/sign-in"
                  className="hp-text-color-primary-1 hp-text-color-dark-primary-2"
              >
                <IntlMessages id="Go back to Login"/>
              </Link>
            </div>
                  </>}
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Footer />
      </Col>
    </Row>
  );
}
