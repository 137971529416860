import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import rootReducer from './rootReducer';

const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk, createDebounce()),
        devTools: process.env.NODE_ENV !== 'production',
});

export default store;
