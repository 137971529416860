const initialState = {
    data: [],
    loading: false,
};

export default function employeeCostReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_ALL_EXPENSES_START':
            return {...state, loading: true};
        case 'FETCH_ALL_EXPENSES_SUCCESS':
            return {...state, data: action.payload, loading: false};
        case 'FETCH_ALL_EXPENSES_STOP':
            return {...state, data: [...state.data], loading: false};
        case 'ACCEPT_EXPENSE_SUCCESS':
            return {
                ...state,
                data: state.data.map(expenses =>
                    expenses.id === action.payload.id ? {...expenses, ...action.payload.data} : expenses
                )
            };
        default:
            return state;
    }
}
