const initialState = {
    paymentMethods: []
};

export default function financeUtilsReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_PAYMENT_METHODS':
            return { ...state, paymentMethods: action.payload };
        default:
            return state;
    }
}
