import * as actionTypes from "./customiseTypes";
import themeConfig from "../../configs/themeConfig";

const saveToLocalStorage = (name, state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(name, serializedState);
    } catch (e) {
        console.error("Could not save state", e);
    }
};

const loadFromLocalStorage = (name) => {
    try {
        const serializedState = localStorage.getItem(name);
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState);
    } catch (e) {
        console.error("Could not load state", e);
        return undefined;
    }
};

const INITIAL_STATE = {
    theme: themeConfig.theme,
    contentWidth: themeConfig.contentWidth,
    sidebarCollapsed: themeConfig.sidebarCollapsed,
    sidebarCollapseButton: themeConfig.sidebarCollapseButton,
    layout: themeConfig.layout,
    navigationFull: themeConfig.navigationFull,
    navigationBg: themeConfig.navigationBg,
    direction: themeConfig.direction,
    language: themeConfig.language,
    user: themeConfig.user,
    token: themeConfig.token,
    userOptions: loadFromLocalStorage(actionTypes.USER_OPTIONS) || themeConfig.userOptions
};

const customiseReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.THEME:
            return {
                ...state,
                theme: action.payload,
            };

        case actionTypes.CONTENT_WIDTH:
            return {
                ...state,
                contentWidth: action.payload,
            };

        case actionTypes.SIDEBAR_COLLAPSED:
            return {
                ...state,
                sidebarCollapsed: action.payload,
            };

        case actionTypes.SIDEBAR_COLLAPSE_BUTTON:
            return {
                ...state,
                sidebarCollapseButton: action.payload,
            };

        case actionTypes.LAYOUT_CHANGE:
            return {
                ...state,
                layout: action.payload,
            };

        case actionTypes.NAVIGATION_FULL:
            return {
                ...state,
                navigationFull: action.payload,
            };

        case actionTypes.NAVIGATION_BG:
            return {
                ...state,
                navigationBg: action.payload,
            };

        case actionTypes.DIRECTION:
            return {
                ...state,
                direction: action.payload,
            };

        case actionTypes.LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };

        case actionTypes.LOGIN:
            const { token, ...user } = action?.payload;
            return {
                ...state,
                user: user || state.user,
                token: token || state.token,
            };

        case actionTypes.LOGOUT:
            return {
                ...state,
                user: null,
                token: null
            };

        case actionTypes.USER_OPTIONS: {
            const userOptions = {
                    ...state.userOptions,
                    ...action.payload
            };
            saveToLocalStorage(action.type, userOptions);
            return {
                ...state,
                userOptions: userOptions,
            }
        }


        default:
            return state;
    }
};

export default customiseReducer;
