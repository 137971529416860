import React from 'react'

import { Col, Row } from 'antd'
import Copyrights from "../../../../utils/Copyrights";

export default function Footer() {
    return (
        <Col className="hp-my-48 hp-px-24">
            <p className="hp-p1-body hp-text-center hp-text-color-black-60 hp-mb-8"><Copyrights/></p>

        </Col>
    )
}
