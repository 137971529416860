import React from "react";
import { Link } from "react-router-dom";

import { Row, Col, Button } from "antd";

import Line from "../line";
import Header from "../header";
import Footer from "../../shared/main_layout/footer";
import IntlMessages from "../../../../layout/components/lang/IntlMessages";
import Background from "../../shared/main_layout/background";

export default function Error404() {
  return (
    <Row className="hp-text-center hp-d-flex hp-overflow-hidden" style={{ minHeight: "100vh" , height: "100vh", margin: "0"}} >
        <Background/>
      <Header />

      <Col className="hp-error-content hp-py-32" span={24}>

      <Row className="hp-h-100" align="middle" justify="center">
          <Col flex="1 0 0" className="hp-px-32">
            <h1 className="hp-error-content-title hp-font-weight-300 hp-text-color-black-bg hp-text-color-dark-0 hp-mb-0">
              404
            </h1>

            <h2 className="h1 hp-mb-16"><IntlMessages id="Oops, Page not found!"/></h2>

            <p className="hp-mb-32 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0"><IntlMessages id="You can go back home."/></p>

            <Link to="/">
              <Button type="primary"><IntlMessages id="Back to Home"/></Button>
            </Link>
          </Col>
        </Row>
      </Col>

        <Col span={24}><Footer /></Col>
    </Row>
  );
}
