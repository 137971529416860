import {Link, useNavigate} from "react-router-dom";

import { Dropdown, Col, Divider, Row } from "antd";
import {Profile, Logout, CpuSetting, Settings, Setting} from 'iconsax-react';

import avatarImg from "../../../assets/images/users/user-0.png";
import {logout as logoutService} from "../../../services/authService";
import {logout} from "../../../redux/customise/customiseActions";
import {useDispatch, useSelector} from "react-redux";
import IntlMessages from "../lang/IntlMessages";
import {RiLogoutCircleRLine, RiSettings3Line} from "react-icons/ri";
import React from "react";

export default function HeaderUser() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customise = useSelector(state => state.customise);

    const handleLogout = () => {
        logoutService();
        dispatch(logout());
    };

  const menu = (
    <div className="hp-user-dropdown hp-border-radius hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-py-24 hp-px-18">

      <Row>
          <Col span={24}>
              <Link
                  to="/settings"
                  className="hp-p1-body hp-d-inline-flex hp-align-items-center hp-font-weight-500 hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-hover-text-color-primary-2 hp-hover-text-color-dark-primary-1"
              >
                  <RiSettings3Line
                      className="remix-icon"
                      size={20}
                  />
                  <span className="hp-ml-8">Ustawienia</span>
              </Link>
          </Col>
          <Divider className="hp-m-18" />

          <Col span={24}>
          <Link
            to="/"
            onClick={handleLogout}
            className="hp-p1-body hp-d-inline-flex hp-align-items-center hp-font-weight-500 hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-hover-text-color-primary-2 hp-hover-text-color-dark-primary-1"
          >
              <RiLogoutCircleRLine
                  className="remix-icon"
                  size={20}
              />
              <span className="hp-ml-8" style={{ lineHeight: 1 }}>Wyloguj się</span>
          </Link>
        </Col>
      </Row>
    </div>
  );

  return (
      <Row className="center hp-align-items-center hp-ml-8">
          <span className="hp-text-color-black-80">
              <IntlMessages id="Hello"/> <span className="hp-text-color-black-100 hp-text-color-dark-40">{customise.user?.firstName}</span>!
          </span>
    <Col className="hp-ml-8">
      <Dropdown overlay={menu} placement="bottomLeft">
        <div className="hp-border-radius-xl hp-cursor-pointer hp-border-1 hp-border-color-dark-80">
          <div className="hp-border-radius-lg hp-overflow-hidden hp-bg-info-4 hp-m-4 hp-d-flex" style={{ minWidth: 32, width: 32, height: 32 }}>
            <img src={avatarImg} alt="User" height="100%" />
          </div>
        </div>
      </Dropdown>
    </Col>
      </Row>
  );
};
