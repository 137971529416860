const initialState = {
    tags: []
};

export default function tagsReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_INFLUENCER_TAGS':
            return { ...state, tags: action.payload };
        default:
            return state;
    }
}
