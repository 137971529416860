export const LOGIN_ENDPOINT = '/auth/signin';
export const GET_LOGGED_USER_ENDPOINT = 'user/getUser';
export const CHANGE_PASSWORD = 'user/updateUserPassword';
export const RESET_PASSWORD_ENDPOINT = '/auth/resetPassword';


// COSTS:

export const GET_PAYMENT_METHODS = 'paymentMethod/Get'
export const DELETE_PAYMENT_METHOD = 'paymentMethod/DeleteById'

// FIXED COSTS:

export const GET_FIXED_COSTS = 'finance/cost/fixedCosts/Settlement/searchByDates'
export const GET_FIXED_COST = 'finance/cost/fixedCosts/GetByFixedCostID'
export const ADD_FIXED_COST = 'finance/cost/fixedCosts/Add'
export const EDIT_FIXED_COST = 'finance/cost/fixedCosts/UpdateById'
export const DELETE_FIXED_COSTS = 'finance/cost/fixedCosts/settlements/DeleteByIds'
export const DELETE_ALL_FIXED_COSTS = 'finance/cost/fixedCosts/DeleteById'

export const DELETE_ALL_FIXED_COST = 'finance/cost/fixedCosts/DeleteById'
export const UPDATE_FIXED_COST_PAYMENT_DATE = 'finance/cost/fixedCosts/UpdateOnlyPaymentDateById'


// VARIABLE COSTS:

export const ADD_VARIABLE_COST = 'finance/cost/variableCosts/Add'
export const GET_VARIABLE_COSTS = 'finance/cost/variableCosts/searchByDates'
export const EDIT_VARIABLE_COST = 'finance/cost/variableCosts/UpdateById'
export const DELETE_VARIABLE_COSTS = 'finance/cost/variableCosts/settlements/DeleteByIds'
export const CONFIRM_VARIABLE_PAYMENT_COST = 'finance/cost/variableCosts/UpdateOnlyPaymentDateById'

// ALL COSTS:

export const GET_ALL_COSTS = 'finance/costs/searchByDates'
export const GET_CASH_FLOW = 'finance/cashFlow'
export const GET_CASH_FLOW_ACCEPTED = 'finance/cashFlowAccepted'


// SALARY:

export const GET_SALARY = 'finance/salary/GetBySalaryID'
export const GET_EMPLOYEE = 'finance/salary/GetBySalaryDetails'
export const GET_SALARIES_OF_EMPLOYEE = 'finance/salary/GetBySalaryByIDAndDates'
export const GET_EMPLOYEES = 'finance/salary/GetAllSalariesDetails'
export const GET_SALARIES = 'finance/salary/Settlement/searchByDates'
export const ADD_EMPLOYEE_WITH_SALARY = 'finance/salary/Add'
export const ADD_SALARIES = 'finance/salary/AddSettlementsToSalaryId'
export const UPDATE_SALARY = "finance/salary/UpdateSettlementById"
export const UPDATE_SALARY_PAYMENT_DATE = 'finance/salary/UpdateOnlyPaymentDateById'
export const UPDATE_EMPLOYEE = 'finance/salary/UpdateById'
export const DELETE_SALARY = 'finance/salary/settlements/DeleteByIds'
export const DELETE_EMPLOYEE = 'finance/salary/DeleteById'

// EMPLOYEE EXPENSES COSTS:

export const ADD_EMPLOYEE_VARIABLE_COST = 'user/finances/variableCostsEmployee/Add'
export const GET_EMPLOYEE_VARIABLE_COSTS = 'user/finances/variableCostsEmployee/GetCostByUserAndDates'
export const EDIT_EMPLOYEE_VARIABLE_COST = 'user/finances/variableCostsEmployee/UpdateById'
export const DELETE_EMPLOYEE_VARIABLE_COSTS = 'user/finances/variableCostsEmployee/settlements/DeleteByIds'
export const GET_ALL_EMPLOYEE_VARIABLE_COSTS_BY_DATES = 'finance/cost/variableCosts/GetUsersVariableCostByDates'
export const ACCEPT_EMPLOYEE_VARIABLE_COSTS = 'finance/cost/variableCosts/UpdateOnlyAcceptedById'

// PROFITS:

export const GET_COLLABORATION_PROFITS = '/profits/GetByDates'

export const GET_VARIABLE_PROFITS = 'finance/revenue/variable/searchByDates'
export const GET_VARIABLE_PROFIT = 'finance/revenue/variable/GetById'
export const ADD_VARIABLE_PROFIT = 'finance/revenue/variable/Add'
export const EDIT_VARIABLE_PROFIT = 'finance/revenue/variable/UpdateById'
export const DELETE_VARIABLE_PROFITS = 'finance/revenue/variable/settlements/DeleteByIds'
export const UPDATE_VARIABLE_PROFIT_PAYMENT_DATE = 'finance/revenue/variable/UpdateOnlyPaymentDateById'

// USERS ADMINISTRATION:

export const UPDATE_USER = '/admin/user/UpdateById'
export const GET_USER_PERMISSIONS = 'admin/user/GetUserPermissionById'
export const GET_USER_DETAILS = 'admin/user/GetById'
export const GET_ALL_USERS = 'admin/user/GetAll'
export const GET_ALL_PERMISSIONS = 'admin/user/GetAllPermission'
export const DELETE_USER = 'admin/user'
export const ADD_USER = 'auth/signup'
export const RESET_USER_PASSWORD = 'admin/user/resetPassword'
export const BLOCK_UNBLOCK_USER = 'admin/user/blockOrUnblock'

// INFLUENCERS

export const GET_ALL_INFLUENCERS = 'influencers/GetAll';
export const GET_INFLUENCER = 'influencers/GetById';
export const ADD_INFLUENCER = 'influencers/Add';
export const DELETE_INFLUENCER = 'influencers/DeleteById';
export const UPDATE_INFLUENCER = 'influencers/UpdateById';

// CLIENTS

export const GET_ALL_CLIENTS = 'campaigns/client/GetAll';
export const GET_CLIENT = 'campaigns/client/GetById';
export const ADD_CLIENT = 'campaigns/client/Add';
export const DELETE_CLIENT = 'campaigns/client/DeleteById';
export const UPDATE_CLIENT = 'campaigns/client/UpdateById';


// CAMPAIGNS

export const ADD_CAMPAIGN = 'campaigns/addCampaignWithAllInformation';
export const ADD_CAMPAIGN_SETTLEMENT = 'campaigns/addCampaignSettlementToCampaignById'
export const GET_UPCOMING_CAMPAIGNS = 'campaigns/UpcomingCampaigns';
export const GET_MONTH_CAMPAIGNS = 'campaigns/getByMonth';
export const GET_MONTH_CAMPAIGNS_BY_DUE_DATE = 'campaigns/getByMonthByDueDate';
export const GET_MONTH_CAMPAIGNS_BY_INVOICE_DATE = 'campaigns/getByMonthByInvoiceDate';

export const GET_UNSETTLED_CAMPAIGNS = 'campaigns/UnsettledCampaigns';
export const GET_CAMPAIGNS_BY_INFLUENCER = 'campaigns/GetByInfluencer';
export const GET_CAMPAIGNS_BY_CLIENT = 'campaigns/GetByCompany';
export const GET_CAMPAIGNS_BY_DATES = 'campaigns/GetByDates';
export const GET_CAMPAIGN = 'campaigns/AllInformation';
export const GET_CALENDAR_BY_DATES = '/campaigns/settlement/GetByDates'
export const UPDATE_CAMPAIGN_SETTLEMENT = 'campaigns/updateCampaignSettlementById'
export const DELETE_CAMPAIGN_SETTLEMENT = 'campaigns/settlement/DeleteById'
export const DELETE_CAMPAIGN_SETTLEMENTS = 'campaigns/settlement/DeleteByIds'
export const UPDATE_PAYOUT_DETAILS_BY_INFLUENCER_SETTLEMENT = '/campaigns/UpdatePaymentDateAndMoneyInCampaignInfluencerSettlementByInfluencerSettlementId'
export const CONFIRM_CAMPAIGN_SETTLEMENT_PAYMENT_DATE = '/campaigns/UpdateOnlyPaymentDateInCampaignSettlementBySettlementId'

// TAGS

export const GET_TAGS = 'influencers/tags'
export const DELETE_TAG = 'influencers/tags'

// CONTENT MANAGEMENT

export const CONTENT_MANAGEMENT = 'storage'

// COMPANY INFORMATION

export const GET_COMPANY_INFORMATION = 'companyInformation/Get'
export const UPDATE_COMPANY_INFORMATION = 'companyInformation/Update'