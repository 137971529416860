const initialState = {
    expenses: [],
    loading: false,
};

export default function expensesReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_EXPENSES_START':
            return {...state, loading: true};
        case 'FETCH_EXPENSES_SUCCESS':
            return {...state, expenses: action.payload, loading: false};
        case 'FETCH_EXPENSES_STOP':
            return {...state, expenses: [...state.expenses], loading: false};
        case 'EDIT_EXPENSE_SUCCESS':
            return {
                ...state,
                expenses: state.expenses.map(expenses =>
                    expenses.id === action.payload.expensesId ? {...expenses, ...action.payload.expensesData} : expenses
                )
            };
        default:
            return state;
    }
}
