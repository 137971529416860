import React from "react";
import {Button, Col} from "antd";
import { RiMoonLine, RiSunLine } from "react-icons/ri";
import {useThemeManager} from "../../../utils/themeManager";
export default function HeaderThemes(props) {
    const { activeTheme, changeTheme } = useThemeManager();

    const toggleTheme = () => {
        const newTheme = activeTheme === 'light' ? 'dark' : 'light';
        changeTheme(newTheme);
    };

    const iconProps = { size: "22", className: "hp-text-color-black-80 hp-text-color-dark-20" };
    return (
        <Col className="hp-d-flex-center">
            <Button
                ghost
                type="primary" className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100" onClick={toggleTheme} {...props.inputFocusProp}
                icon={activeTheme === 'light' ? <RiMoonLine {...iconProps} /> : <RiSunLine {...iconProps} />}>
            </Button>
        </Col>
    );
}
