const initialState = {
    influencers: [],
    loading: false,
};

export default function influencerAdministrationReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_ALL_INFLUENCER_START':
            return { ...state, influencers: action.payload, loading: true};
        case 'GET_ALL_INFLUENCER_STOP':
            return { ...state, influencers: action.payload, loading: false };
        default:
            return state;
    }
}
