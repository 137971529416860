import {Suspense, useEffect, useState} from "react";

// Motion
import {motion} from 'framer-motion';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {theme} from "../redux/customise/customiseActions";

// Router
import {Navigate, Route, Routes} from 'react-router-dom';

// Routes
import {Routes as DefinedRoutes} from "./routes";

// Layouts
import VerticalLayout from "../layout/VerticalLayout";
import FullLayout from "../layout/FullLayout";

// Components
import Home from "../view/home";
import Error404 from "../view/pages/errors/404";
import Login from "../view/pages/authentication/login";
import RecoverPassword from "../view/pages/authentication/recover-password";
import {hasPermission} from "../utils/permissionsManager";
import RestorePassword from "../view/pages/authentication/recover-password/restore-password";
import Environments from "../utils/environments";

export default function Router() {

    const customise = useSelector(state => state.customise)
    const dispatch = useDispatch()
    const isAuthenticated = customise.user && customise.token;

    useEffect(() => {
        document.querySelector("body").classList.add(customise.theme)
        dispatch(theme(customise.theme))
    }, [])

    useEffect(() => {
        if (customise.direction == "ltr") {
            document.querySelector("html").setAttribute("dir", "ltr");
        } else if (customise.direction == "rtl") {
            document.querySelector("html").setAttribute("dir", "rtl");
        }
    }, [])

    const Layouts = {VerticalLayout, FullLayout};

    return (

        <Routes>
            <Route
                path="/sign-in"
                element={
                    isAuthenticated ? (
                        <Navigate to="/" />
                    ) : (
                        <FullLayout>
                            <Login />
                        </FullLayout>
                    )
                }
            />
            {isAuthenticated ? (
                <Route exact path="/" element={<VerticalLayout><Home/></VerticalLayout>} />
            ) : (
                <>
                    <Route path="/recover-password" element={<RecoverPassword />} />
                    <Route path="/restore-password/:token" element={<RestorePassword/>} />
                    <Route path="/" element={<Navigate to="/sign-in" />} />
                </>
            )}
            {isAuthenticated && DefinedRoutes.map((route) => {
                const RouteComponent = route.component;
                const Layout = Layouts[route.layout || "VerticalLayout"];
                const userHasPermission = (route.permissions && customise.user?.permissions ?
                    hasPermission(customise.user.permissions, route.permissions)
                    : true) && (route.environment ? route.environment === process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === Environments.staging : true);

                return (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={
                            userHasPermission ? (
                            <Layout>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <RouteComponent />
                                </Suspense>
                            </Layout>
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />
                );
            })}

            <Route path="*" element={<Error404 />} />

        </Routes>
    );
};
