import {message} from "antd";
import errorMessages from "./errorMessages";

const handleErrorResponse = (response) => {
      if(response.data)
    {
        console.error(response.data)

        if(response.data.enumMessage)
        {
            if(response.data.enumMessage === 'BAD_CREDENTIALS') return;

            message.error(errorMessages(response.data.enumMessage));

            return;
        }

        message.error('Wystąpił błąd podczas przetwarzania operacji');

        return;
    }

    console.error(response)
    message.error('Wystąpił błąd podczas przetwarzania operacji');
}

const handleError = (error) => {
    if(error.response){
        console.error(error.response)

        if(error.response.status === 401)
        {
            message.error("Błąd autoryzacji")

            return
        }

        message.error('Wystąpił błąd podczas przetwarzania operacji');

        return;
    }

    console.error(error)

    if(error.code === "ERR_NETWORK")
    {
        message.error("Wystąpił błąd połączenia z serwerem. Spróbuj ponownie później")

        return
    }

    if(error.status === 401)
    {
        message.error("Błąd autoryzacji")

        return
    }

    message.error('Wystąpił błąd podczas przetwarzania operacji');
}

export {handleErrorResponse, handleError};