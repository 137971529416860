import {createGenericActions} from "../general/generalActions";

import {
    ADD_USER, BLOCK_UNBLOCK_USER,
    DELETE_USER, GET_ALL_PERMISSIONS,
    GET_ALL_USERS,
    GET_USER_DETAILS, RESET_USER_PASSWORD,
    UPDATE_USER
} from "../../services/utils/apiEndpoints";
import {message} from "antd";
import apiAxios from "../../services/utils/apiAxios";

export const {
    getAll: getAllUsers,
    getOne: getUser,
    edit: editUser,
    add: addUser,
    deleteEntities: deleteUsers
} = createGenericActions('USER', {
    ADD: ADD_USER,
    DELETE: DELETE_USER,
    GET_ALL: GET_ALL_USERS,
    GET_ONE: GET_USER_DETAILS,
    UPDATE: UPDATE_USER
});

export const resetUserPassword = (userId, userPassword) => {
    return async (dispatch) => {
        try {
            const response = await apiAxios.put(`${RESET_USER_PASSWORD}/${userId}`, userPassword );
            if (response) {
                message.success("Pomyślnie zmieniono hasło użytkownika");
                return userPassword;
            } else {
                message.error("Błąd podczas zmiany hasła użytkownika");
                return null;
            }
        } catch (err) {
            console.error(err);
            message.error("Błąd podczas zmiany hasła użytkownika");
            return null;
        }
    };
};

export const fetchPermissions = () => async dispatch => {
    try {
        const response = await apiAxios.get(GET_ALL_PERMISSIONS);
        if (response.data) {
            dispatch({type: 'FETCH_PERMISSIONS_SUCCESS', payload: response.data});
        } else {
            message.error('Błąd podczas pobierania możliwych uprawnień')
            console.error('Błąd podczas pobierania uprawnień');
        }
    } catch (error) {
        message.error('Błąd podczas pobierania możliwych uprawnień')
        console.error('Błąd podczas pobierania uprawnień', error);
    }
};

export const unBlockUser = (userId) => {
    return async (dispatch) => {
        try {
            const response = await apiAxios.put(`${BLOCK_UNBLOCK_USER}/${userId}?block=false`);
            if (response) {
                message.success("Pomyślnie odblokowano konto użytkownika");
                return true;
            } else {
                message.error("Błąd podczas próby odblokowywania konta użytkownika");
                return null;
            }
        } catch (err) {
            console.error(err);
            message.error("Błąd podczas próby odblokowywania konta użytkownika");
            return null;
        }
    };
};