import { Col, Layout, Row } from "antd";
import Copyrights from "../../../utils/Copyrights";
import React from "react";

const APP_VERSION = process.env.REACT_APP_VERSION;

export default function MenuFooter() {
  const { Footer } = Layout;

  return (
    <Footer className="hp-bg-color-black-20 hp-bg-color-dark-90">
      <Row align="middle" justify="space-between">
        <Col md={12} span={24}>
          <p className="hp-badge-text hp-font-weight-600 hp-mb-0 hp-text-color-dark-30">
            <Copyrights/> | CRM v.{APP_VERSION}
          </p>
        </Col>
      </Row>
    </Footer>
  );
};
