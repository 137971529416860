const initialState = {
    data: {},
    loading: false
};

export default function contentReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_CONTENT':
            return { ...state, data: { ...state.data, [action.payload.type]: action.payload.data }};
        case 'LOADING_CONTENT':
            return { ...state, loading: action.payload };
        default:
            return state;
    }
}