const initialState = {
    salary: [],
    employee: [],
    loading: false,
};

export default function salaryReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_SALARIES_START':
            return {...state, loading: true};
        case 'FETCH_SALARIES_SUCCESS':
            return {...state, salary: action.payload, loading: false};
        case 'FETCH_SALARIES_STOP':
            return {...state, salary: [...state.salary], loading: false};
        case 'FETCH_EMPLOYEES_START':
            return {...state, loading: true};
        case 'FETCH_EMPLOYEES_SUCCESS':
            return {...state, employee: action.payload, loading: false};
        case 'FETCH_EMPLOYEES_STOP':
            return {...state, employee: [...state.employee], loading: false};

        default:
            return state;
    }
}
