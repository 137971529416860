import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { theme as themeAction } from '../redux/customise/customiseActions'
import {setLongTermCookie, THEME} from "./CookieManager";

export function useThemeManager() {
    const customise = useSelector(state => state.customise);
    const dispatch = useDispatch();
    const [activeTheme, setActiveTheme] = useState(customise.theme);

    useEffect(() => {
        setActiveTheme(customise.theme);
    }, [customise]);

    const changeTheme = (newTheme) => {
        dispatch(themeAction(newTheme));
        document.querySelector("body").classList.remove('light', 'dark');
        document.querySelector("body").classList.add(newTheme);

        const htmlElement = document.documentElement;
        htmlElement.style.colorScheme = newTheme;

        setLongTermCookie(THEME, newTheme);
    };

    return { activeTheme, changeTheme };
}