const initialState = {
    data: null,
    loading: false
};

export default function companyReducer(state = initialState, action) {
    switch (action.type) {
        case `GET_ALL_COMPANY_INFORMATION_START`:
            return { ...state, loading: true };
        case `GET_ALL_COMPANY_INFORMATION_STOP`:
            return { ...state, data: action.payload, loading: false };
        default:
            return state;
    }
}