import React from "react";
import { Link } from "react-router-dom";

import { Divider, Avatar, Row, Col } from "antd";
import {RiLifebuoyLine, RiPhoneLine, RiSettings3Line} from "react-icons/ri";

export default function MenuFooter(props) {
  return (

      <Row
        className="hp-sidebar-footer hp-bg-color-dark-90"
        align="middle"
        justify={props.collapsed ? "center" : "start"}
      >
        <Divider className="hp-border-color-black-40 hp-border-color-dark-70 hp-mt-0" />

        <Row>
{/*          <Col span={24} className={`hp-d-inline-flex hp-align-items-center ${props.collapsed && "hp-d-flex-justify-center"}`}>
            <Link
                to="#"
                className="hp-p1-body hp-d-inline-flex hp-align-items-center hp-font-weight-500 hp-text-color-primary-1 hp-text-color-dark-30 hp-hover-text-color-primary-2 hp-hover-text-color-dark-0"
            >
              <RiLifebuoyLine
                  className="remix-icon"
                  size={24}
              />
              {!props.collapsed && <span className="hp-ml-16">Pomoc</span>}
            </Link>
          </Col>*/}

          <Col span={24} className={`hp-d-inline-flex hp-align-items-center ${props.collapsed && "hp-d-flex-justify-center"}`}>
            <Link
                to="/settings"
                className="hp-p1-body hp-d-inline-flex hp-align-items-center hp-font-weight-500 hp-text-color-primary-1 hp-text-color-dark-30 hp-hover-text-color-primary-2 hp-hover-text-color-dark-0"
            >
              <RiSettings3Line
                  className="remix-icon"
                  size={24}
              />
              {!props.collapsed && <span className="hp-ml-16">Ustawienia</span>}
            </Link>
          </Col>
        </Row>


      </Row>
  );
};
