const initialState = {
    isVisible: false,
    itemsToDelete: [],
    deleteEndpoint: null,
    onSuccess: null
};

const deleteConfirmationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_DELETE_MODAL':
            return {
                ...state,
                isVisible: true,
                itemsToDelete: action.payload.items,
                deleteEndpoint: action.payload.endpoint,
                onSuccess: action.payload.onSuccess
            };
        case 'HIDE_DELETE_MODAL':
            return {
                ...state,
                isVisible: false,
                itemsToDelete: [],
                deleteEndpoint: null
            };
        default:
            return state;
    }
};

export default deleteConfirmationReducer;
