const themeConfig = {
    theme: "light", // light - dark
    contentWidth: "full", // full - boxed
    sidebarCollapsed: false,
    sidebarCollapseButton: true,
    layout: "VerticalLayout", // VerticalLayout - HorizontalLayout
    navigationFull: false,
    navigationBg: false,
    direction: "ltr", // ltr - rtl
    language: "pl", // tr - en - es - fr
    token: undefined,
    user: undefined,
    userOptions: {
        searchDateType: 'eventDate',
        calendarType: 'dayGridMonth'
    }
}

export default themeConfig
