const initialState = {
    users: [],
    loading: false,
    permissions: []
};

export default function usersAdministrationReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_ALL_USER_START':
            return { ...state, users: action.payload, loading: true};
        case 'GET_ALL_USER_STOP':
            return { ...state, users: action.payload, loading: false };
        case 'FETCH_PERMISSIONS_SUCCESS':
            return { ...state, permissions: action.payload };
        default:
            return state;
    }
}
