const initialState = {
    profits: [],
    loading: false,
};

export default function variableProfitsReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_VARIABLE_PROFITS_START':
            return {...state, loading: true};
        case 'FETCH_VARIABLE_PROFITS_SUCCESS':
            return {...state, profits: action.payload, loading: false};
        case 'FETCH_VARIABLE_PROFITS_STOP':
            return {...state, profits: [...state.profits], loading: false};
        case 'EDIT_VARIABLE_PROFIT_SUCCESS':
            return {
                ...state,
                profits: state.profits.map(profit =>
                    profit.id === action.payload.id ? {...profit, ...action.payload.data} : profit
                )
            };
        default:
            return state;
    }
}
