import React from "react";

import { Row } from "antd";

import PageTitle from "../../layout/components/content/page-title";
import environmentSettings from "../../assets/environmentSettings.json";

export default function Home() {
  return (
    <Row gutter={[32, 32]}>
      <PageTitle
        pageTitle={`CRM - ${environmentSettings.name || "Head Start Agency"}`}
      />

   {/*   <Col span={24}>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Card className="hp-border-color-black-40 hp-border-color-dark-80">
              <h4>Strona Główna 🏠</h4>

              <Row>
                <Col >
                  <h5>Localhost</h5>
                  <p className="hp-p1-body">🍃 Swagger:  <a href="http://localhost:8080/swagger-ui.html" target="_blank">localhost:8080/swagger-ui.html</a></p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h5>Staging</h5>
                  <p className="hp-p1-body">🍃 Swagger:  <a href="https://hsa-stage.azurewebsites.net/swagger-ui.html" target="_blank">https://hsa-stage.azurewebsites.net/swagger-ui.html</a></p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h5>Production</h5>
                  <p className="hp-p1-body">🍃 Swagger:  <a href="https://headstartagency.azurewebsites.net/swagger-ui.html" target="_blank">https://headstartagency.azurewebsites.net/swagger-ui.html</a></p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>*/}
    </Row>
  );
}
