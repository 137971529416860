const initialState = {
    fixedCosts: [],
    loading: false,
};

export default function fixedCostsReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_FIXED_COSTS_START':
            return { ...state, loading: true };
        case 'FETCH_FIXED_COSTS_SUCCESS':
            return { ...state, fixedCosts: action.payload, loading: false };
        case 'FETCH_FIXED_COSTS_STOP':
            return { ...state, fixedCosts: [...state.fixedCosts, action.payload], loading: false  };
        case 'EDIT_FIXED_COST_SUCCESS':
            return {
                ...state,
                fixedCosts: state.fixedCosts.map(cost =>
                    cost.id === action.payload.fixedCostId ? { ...cost, ...action.payload.fixedCostData } : cost
                )
            };
        case 'DELETE_FIXED_COSTS_SUCCESS':
            return {
                ...state,
                fixedCosts: state.fixedCosts.filter(cost => !action.payload.includes(cost.id))
            };
        default:
            return state;
    }
}
