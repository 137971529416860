import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import {Row, Col, Form, Input, Button, message} from "antd";
import Background from "../../shared/main_layout/background";
import Header from "../header";
import Footer from "../../shared/main_layout/footer";
import IntlMessages, {useIntlMessage} from "../../../../layout/components/lang/IntlMessages";
import ReCaptcha from "../../../../services/reCaptchaService";
import {login as loginService, resetPassword, restorePassword} from "../../../../services/authService";
import {login} from "../../../../redux/customise/customiseActions";
import PasswordInput from "../../shared/form/PasswordInput";
import {useParams} from "react-router";
import * as cosole from "@craco/craco/dist/lib/logger";

export default function RestorePassword() {

  const intlMessage = useIntlMessage();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
    const [restoreComplete, setRestoreComplete] = useState(false);
    const [form] = Form.useForm();


    const handleRestorePassword = async () => {
        const password = String(form.getFieldValue('new-password'));

        if(!password) {
            setError("Wprowadź nowe hasło.");
            return
        }
        if(!token) {
            message.error("Błędny link resetu hasła. Sprawdź poprawność adresu.")
            return;
        }
        setLoading(true);
        setError(null);
        try {
            const response = await restorePassword(token, password);
            if (response?.data) {
                setRestoreComplete(true);
            } else if (response && response.error) {
                setError(intlMessage(response.error));
            } else {
                message.error("Wystąpił błąd podczas zmiany hasła");
            }
        } catch (error) {
           console.log(error)
            message.error("Wystąpił błąd podczas zmiany hasła");
        } finally {
            setLoading(false);
        }
    };

    return (
    <Row className="hp-authentication-page hp-d-flex" style={{ flexDirection: "column" }}>
      <Background />

      <Col span={24}>
        <Header />
      </Col>

      <Col flex="1 0 0" className="hp-px-32">
        <Row className="hp-h-100 hp-m-auto" align="middle" style={{ maxWidth: 400 }}>
          <Col span={24}>
            <h2 className="hp-text-center">Resetowanie hasła</h2>

              { restoreComplete ?
                  <>
                      <p className="hp-text-color-black-80 hp-text-center">Twoje hasło zostało pomyślnie zmienione.</p>

                      <Button block type="primary" onClick={() => navigate("/sign-in")} className={"hp-p-0 hp-mt-16"}>
                          Powrót do logowania
                      </Button>
                  </>
                  :
                  <>
              {error && <p style={{ color: "red" }} className="hp-text-center" dangerouslySetInnerHTML={{__html: error}}/>}


            <Form
              layout="vertical"
              form={form}
              name="basic"
              className="hp-mt-sm-16 hp-mt-32"
              onFinish={handleRestorePassword}
            >

                <PasswordInput label={<span
                    className="hp-input-label hp-text-color-black-100 hp-text-color-dark-0">
                                Nowe hasło:
                            </span>}
                               name="new-password"
                />

                <Form.Item
                    label={<span
                        className="hp-input-label hp-text-color-black-100 hp-text-color-dark-0">
                    Potwierdź nowe hasło:
                  </span>}
                    name="confirm-new-password"
                    dependencies={["new-password"]}
                    rules={[{
                        required: true, message: "Proszę potwierdź nowe hasło",
                    }, ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("new-password") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error("Nowe hasła nie pasują do siebie."));
                        },
                    }),]}
                >
                    <Input.Password placeholder="Potwierdź nowe hasło"/>
                </Form.Item>


              <Form.Item className="hp-mt-16 hp-mb-8">
                  <Button block type="primary" htmlType="submit" loading={loading}>
                      Zmień hasło
                </Button>
              </Form.Item>
            </Form>

            <div className="hp-form-info hp-text-center">

              <Link
                  to="/sign-in"
                  className="hp-text-color-primary-1 hp-text-color-dark-primary-2"
              >
                <IntlMessages id="Go back to Login"/>
              </Link>
            </div>
                  </>}
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Footer />
      </Col>
    </Row>
  );
}
